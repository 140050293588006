
.container {
  padding-top: 20px;
  width: 80%;
  .card {
    border: 0 !important;
    padding: 30px;
    .card-option {
      background: #f2f1f4;
      padding: 20px 40px;
      height: 100px;
      border-radius: 10px;
      color: #000;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .tag {
        margin-left: 5px;
        background: #fff;
        color: #fc7234;
      }
      &.grayed-out,
      &.grayed-out > span {
        color: #b1b1b1 !important;
      }
      .question {
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #fc7234;
        color: #fff;
        font-size: 0.5em;
      }
      .title {
        margin-bottom: 0;
      }
      &:hover {
        background: darken(#f2f1f4, 5%);
      }
    }

    ::v-deep input.input {
      background-color: #f2f1f4;
    }
  }
}

.title.is-3 {
  margin-top: 30px;
}

@media only screen and (max-width: 1700px) {
  .container {
    width: 100%;
    .step-item {
      margin: 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .hide-1000 {
    display: none;
  }
}
